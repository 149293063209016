
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import listConnectAvailable from '../../../store/platform'
import { ElMessage, ElMessageBox } from 'element-plus'

interface Result {
  data: []
  message: string
  status: boolean
}

type FormRequest = {
  name: string
  email: string
  phone: string
  company: string
  conections: any[]
  ConnectorsOfInterest: any[]
}

@Options({
  components: {},
  watch: {
    selectedHub: 'updateTargetList',
    selectedTarget: 'setConnectorsOfInterest'
  }
})
export default class RequestAccess extends Vue {
  refForm = 'signUpForm'

  formRequest: FormRequest = {
    name: '',
    email: '',
    phone: '',
    company: '',
    conections: [],
    ConnectorsOfInterest: []
  }
  currentForm = 'login'
  showHeader = true
  list: any = []
  hubList: any = []
  targetList: any = []
  selectedHub = ''
  selectedTarget = []
  expireVerifyHash = new Date()
  rules = {
    name: [
      {
        required: true,
        message: 'Please input your name',
        trigger: 'blur'
      }
    ],
    email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur'
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change']
      }
    ],
    phone: [
      {
        min: 14,
        max: 14,
        message: 'Must be a correct phone number.',
        trigger: 'blur'
      }
    ],
    ConnectorsOfInterest: [
      {
        required: true,
        message: 'Please choose at least 1 connector',
        trigger: 'blur'
      }
    ]
  }

  created() {
    listConnectAvailable.list.forEach((connect) => {
      // this.list.push({
      //   name: connect.hub.toUpperCase() + ' - ' + connect.target.toUpperCase()
      // })

      this.hubList.push({
        name: connect.hub.toUpperCase()
      })
    })
  }

  updateTargetList() {
    listConnectAvailable.list.forEach((connect) => {
      if (connect.target.toUpperCase() !== this.selectedHub) {
        this.targetList.push({
          name: connect.target.toUpperCase()
        })
      }
    })
  }

  setConnectorsOfInterest() {
    this.formRequest.ConnectorsOfInterest = []

    this.selectedTarget.forEach((target: any) => {
      // console.log(`${this.selectedHub.toUpperCase()} - ${target.toUpperCase()}`)
      this.formRequest.ConnectorsOfInterest.push(
        `${this.selectedHub.toUpperCase()} - ${target.toUpperCase()}`
      )
    })
  }

  handleUserInput(e: any) {
    var x = e.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    this.formRequest.phone = !x[2]
      ? x[1]
      : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }

  async submitForm() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()

    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result

    // console.log(`formRequest: ${JSON.stringify(this.formRequest)}`)

    res = await axios.post(`request-early-access`, this.formRequest, {})

    if (res.status) {
      ElMessageBox.alert(
        `
            <h2 style="color:#215ca2"> Thank you for your interest in iOmniC!</h2>
            <p> A iOmniC representative will contact you shortly.</p>
         `,
        {
          dangerouslyUseHTMLString: true
        }
      )

      this.formRequest = {
        name: '',
        email: '',
        phone: '',
        company: '',
        conections: [],
        ConnectorsOfInterest: []
      }
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
}
