
import { Options, Vue } from "vue-class-component";
import SigninForm from "../components/SigninForm.vue";

@Options({
  components: {
    SigninForm
  },
})

export default class Auth extends Vue {}
