
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessage } from "element-plus";
interface Result {
  message: "";
}
@Options({
  components: {},
})
export default class ResetPassword extends Vue {
  refForm = "resetPasswordForm";
  emailRequest = {
    email: "",
  };
  rules = {
    email: [
      {
        required: true,
        message: "Please input email address",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: ["blur", "change"],
      },
    ],
  };
  async clickRequestResetPassword() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Result = await axios.post(
      "/request-reset-password",
      this.emailRequest
    );
    if (res.message) {
      ElMessage(res.message);
      if (res.message === "Email not exist !!!") {
        this.emailRequest.email = "";
      } else {
        setTimeout(() => {
          window.location.href = `${process.env.VUE_APP_DOMAIN}`
        }, 500);
      }
    }

    this.$store.dispatch("setLoading", false, { root: true });
  }
}
