
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";

interface Result {
  data: [];
  message: string;
  status: boolean;
}
@Options({
  components: {},
})
export default class SignupForm extends Vue {
  refForm = "signUpForm";
  formSignUp = {
    nameAccount: "",
    email: "",
    password: "",
    passwordConfirm: "",
  };
  currentForm = "login";
  showHeader = true;
  expireVerifyHash = new Date();
  rules = {
    email: [
      {
        required: true,
        message: "Please input email address",
        trigger: "blur",
      },
      {
        type: "email",
        message: "Please input correct email address",
        trigger: ["blur", "change"],
      },
    ],
    password: [
      {
        required: true,
        message: "Please input password",
        trigger: "blur",
      },
      {
        min: 6,
        max: 25,
        message: "Password must be at least 6 characters",
        trigger: "blur",
      },
    ],
    passwordConfirm: [
      {
        required: true,
        message: "Please input password again",
        trigger: "blur",
      },
      {
        min: 6,
        max: 25,
        message: "Password must be at least 6 characters",
        trigger: "blur",
      },
    ],
  };
  async submitForm() {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    // call api
    const siginUpResponse = await this.$store.dispatch(
      "auth/signUp",
      this.formSignUp
    );
    this.$message(siginUpResponse.message);
    if (siginUpResponse.message === "Account sign up successful !!!") {
      setTimeout(() => {
        let formSignIn = {
          email: this.formSignUp.email,
          password: this.formSignUp.password,
        };
        this.signIn(formSignIn);
      }, 200);
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async signIn(formSignIn: any) {
    await this.$store.dispatch("setLoading", true, { root: true });

    let res: Result;
    res = await axios.post(`sign-in`, formSignIn, {
      params: {
        type: "signUp",
      },
    });
    if (res.status) {
      this.currentForm = "verify_email";
      this.showHeader = false;
      this.expireVerifyHash = new Date(Date.now() + 15 * 60000);
    } else {
      this.$message.error(res.message);
    }

    await this.$store.dispatch("setLoading", false, { root: true });
  }
}
